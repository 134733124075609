import t from '@jetshop/intl';
import ChannelSelector, {
  SelectorBody,
  Title
} from '@jetshop/ui/ChannelSelector/ChannelSelector';
import MiniSelector from '@jetshop/ui/ChannelSelector/MiniSelector';
import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import flattenCountries from './flattenCountries';
import { theme } from '../../../Theme';
import { Fragment } from 'react';
import { ReactComponent as Person } from '../../../../svg/Person.svg';

const Channel = styled('div')`
  height: 30px;
  font-size: 14px;
  padding: 0 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 200ms;
  background-color: transparent;
  color: ${theme.colors.lightgrey};
  font-weight: 400;
  opacity: 0.75;
  justify-content: flex-start;
  border-radius: 50rem;
  margin: 0.5rem 0;

  :hover {
    opacity: 1;
  }

  &.active {
    color: ${theme.colors.black};
    font-weight: 600;
    opacity: 1;
    background: ${theme.colors.lightgrey};
  }

  span {
    white-space: nowrap;
    line-height: 30px;
  }
`;

const CountryFlag = styled('img')`
  height: 24px;
  width: 24px;
  margin-right: 15px;
`;

// const CheckboxGroupContainer = styled('div')`
//   & > div {
//     position: relative;
//     padding-left: 80px;
//     + div {
//       margin-top: 1em;
//     }
//     & > span {
//       position: absolute;
//       top: 0;
//       left: 0;
//       color: #000;
//       & ~ label {
//         margin-top: 0.6rem;
//         color: #000;
//       }
//       & + label {
//         margin-top: 0;
//       }
//     }
//   }
//   input ~ span {
//     border: 1px solid ${theme.colors.blue};
//   }
//   label input:checked ~ span {
//     background-color: ${theme.colors.blue};
//   }
// `;

const StyledTitle = styled(Title)`
  text-align: center;
  font-weight: 400;
  border-bottom: 1px solid ${theme.colors.white};
  padding: 2px 0 16px;
  margin-bottom: 12px;
  font-size: 1rem;
  color: ${theme.colors.white};
`;

const actions = css`
  display: flex;
  && button {
    width: 100%;
    margin: 0;
    max-width: 100%;
    &.commit-button {
      background: ${theme.colors.blue};
      color: white;
    }
  }
`;

const selectorStyle = css`
  border: 0;
  background: transparent;
`;

const B2B = styled(Person)`
  width: 26px;
  margin-right: 14px;
  margin-left: -1px;
`;

const Selector = ({
  type = MiniSelector,
  channels,
  selectedChannel,
  hideTarget,
  updateChannel,
  importedStyles
}) => {
  const [countries] = useState(flattenCountries(channels));
  const Comp = type;

  const filteredCountries = countries.filter(
    ({ channel }) => channel?.name !== 'Danmark'
  );

  return (
    <ChannelSelector
      channels={channels}
      initialChannel={selectedChannel}
      render={({
        channels,
        currencyItems,
        languageItems,
        selectedChannel,
        onSelect,
        hasChanged,
        onCurrencySelect,
        onLanguageSelect
      }) => {
        if (hasChanged) {
          updateChannel(selectedChannel);
          hideTarget();
        }

        return (
          <Comp
            className={
              selectorStyle + (importedStyles ? ' ' + importedStyles : '')
            }
          >
            <SelectorBody>
              <StyledTitle>{t('Select your country')}</StyledTitle>
              {filteredCountries.map(({ name, code, channel, languages }) => (
                <Fragment key={name + channel.name}>
                  {languages.map(language => {
                    const multiLang = languages.length > 1;
                    const langCode = language.culture
                      .substr(0, 2)
                      .toUpperCase();
                    const classes =
                      channel.id === selectedChannel.id &&
                      name === selectedChannel.country.name &&
                      language.name === selectedChannel.language.name
                        ? 'active'
                        : '';

                    if (channel.name === 'B2B') {
                      return (
                        <Channel
                          key={channel.id + name + langCode}
                          className={classes}
                          onClick={() =>
                            onSelect(channel.id, language.culture, null, code)
                          }
                        >
                          <B2B />
                          <span>B2B</span>
                        </Channel>
                      );
                    }

                    //Hide english channel
                    if (channel?.id == '6') {
                      return <></>;
                    }

                    return (
                      <Channel
                        key={channel.id + name + langCode}
                        className={classes}
                        onClick={() =>
                          onSelect(channel.id, language.culture, null, code)
                        }
                      >
                        <CountryFlag
                          src={`https://countryflags.jetshop.io/${code}/flat/64.png`}
                          alt="Country Flag"
                        />
                        <span>
                          {name} {multiLang ? '(' + langCode + ')' : ''}
                        </span>
                      </Channel>
                    );
                  })}
                </Fragment>
              ))}
            </SelectorBody>
          </Comp>
        );
      }}
    />
  );
};

export default Selector;
