import { styled } from 'linaria/react';
// import React, { useContext, useEffect, useState } from 'react';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { theme } from '../Theme';
import CategoryHeaderLoadingState from './CategoryHeaderLoadingState';
import {
  AddConfigurationClickTracker,
  TrackPageLoad
} from '../DataLayer/DataLayer';
import Spinner from '../ui/Spinner';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import ShowroomOption from './Showroom/ShowroomOption';
import ShowroomAccordion from './Showroom/ShowroomAccordion';
import { useHistory } from 'react-router-dom';
import {
  fetchProductFile,
  generateCodeString,
  generateRandomConfigKey,
  getAllFanModelDefaultValues
} from './Showroom/ShowroomHelper';
import { getShoowroomData, showroomData } from './Showroom/ShowroomData';
import {
  CONTENT_SHOWROOM as CONTENT_SHOWROOM_KEY,
  FALLBACK_IMAGE,
  FAN_MODEL_KEY,
  FAN_TYPE_KEY,
  IMAGE_BASE_URL,
  KITCHEN_COLOR_KEY,
  KITCHEN_ENV_KEY
} from './Showroom/ShowroomConstants';
import { useIntl } from '@jetshop/intl';
import { useSiteSettingsQuery } from '../GlobalSettings/useSiteSettings';
import ShowroomBuyButton from './Showroom/ShowroomBuyButton';
import { ReactComponent as DownloadIcon } from '../../svg/Download.svg';

const Container = styled('div')`
  padding: 1.25rem 1.25rem 0 1.25rem;

  ${theme.below.lg} {
    padding: 0.5rem 0.5rem 0 0.5rem;
  }
`;

const CategoryWrapper = styled('div')`
  h1 {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
  }
`;

const ShowroomContainer = styled('div')`
  display: flex;
  gap: 1rem;

  img {
    width: 100%;
  }

  #mobile-showroom-button {
    display: none;
  }

  ${theme.below.lg} {
    flex-direction: column;
    gap: 0.75rem;
    #mobile-showroom-button {
      margin-top: 0.5rem;
      display: block;
    }
  }

  .fallback-image {
    max-height: 800px;
    object-fit: contain;
  }

  .image-container {
    position: relative;
    width: 80%;
    ${theme.below.xl} {
      width: 70%;
    }

    ${theme.below.lg} {
      width: 100%;
    }
  }

  .accordion-container {
    width: 20%;
    ${theme.below.xl} {
      width: 30%;
    }

    ${theme.below.lg} {
      width: 100%;
    }
  }

  .showroom-title {
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-size: 30px;
  }

  .showroom-option-container {
    margin-top: 0.5rem;
  }
`;

const AccordionContainer = styled('div')`
  ${theme.above.lg} {
    height: ${props =>
      props.height}px; // Set height dynamically based on img height
    overflow-y: auto; // Enable vertical scrolling if content is hidden
  }

  & > div {
    border-top: 1px solid #a5a5a5;
  }

  & > div:last-child {
    border-bottom: 1px solid #a5a5a5;
  }
`;

const ImageContainer = styled('div')`
  position: relative;

  div {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  a {
    background-color: #ffffff10;
    border-radius: 100px;
  }

  svg {
    width: 40px;
    height: 40px;
    transition: all 0.5s ease;
  }

  svg:hover {
    scale: 1.1;
  }

  ${theme.below.lg} {
    button {
      padding: 0.75rem 1.5rem !important;
      font-size: 16px;
    }

    svg {
      width: 36px;
      height: 36px;
    }
  }
`;

const Category = ({ category, result, transparencyContext }) => {
  const {
    selectedChannel: {
      language: { culture }
    }
  } = useContext(ChannelContext);

  const cultureCode = culture.replace(/-/g, '_');
  const [activeAccIndex, setActiveAccIndex] = useState(null);
  const [activeImageUrl, setActiveImageUrl] = useState('');
  const [activeFallbackUrl, setActiveFallbackUrl] = useState('');
  // const [activeSelectionString, setActiveSelectionString] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([
    { id: KITCHEN_ENV_KEY, value: 'k3' },
    { id: KITCHEN_COLOR_KEY, value: 's1' },
    { id: FAN_TYPE_KEY, value: 'Kitchen_f' }
  ]);
  const [selectedFanModel, setSelectedFanModel] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [randConfigValue, setRandConfigValue] = useState(null);
  const t = useIntl();
  const history = useHistory();
  const [getSetting, loading] = useSiteSettingsQuery();
  const showroomSettings = getSetting(null, CONTENT_SHOWROOM_KEY);
  const showRoomImgRef = useRef(null);
  const [accordionHeight, setAccordionHeight] = useState(800); // Set default height

  useEffect(() => {
    const updateAccordionHeight = () => {
      if (showRoomImgRef.current && showRoomImgRef.current.clientHeight > 20) {
        setAccordionHeight(showRoomImgRef.current.clientHeight);
      } else {
        setAccordionHeight(600); // Maintain default height if image ref isn't available
      }
    };

    window.addEventListener('resize', updateAccordionHeight);
    return () => window.removeEventListener('resize', updateAccordionHeight);
  }, []);

  useEffect(() => {
    if (!transparencyContext.headerIsOpaque) {
      transparencyContext.setHeaderAsOpaque(true);
    }
  }, []);

  useEffect(() => {
    const checkImageExists = async url => {
      const response = await fetch(url);
      if (response.status !== 404) {
        setActiveFallbackUrl('');
        return;
      }
      setActiveFallbackUrl(FALLBACK_IMAGE);
    };
    setActiveImageUrl(
      IMAGE_BASE_URL +
        generateCodeString(selectedFanModel, selectedOptions) +
        '.jpg'
    );
    checkImageExists(
      IMAGE_BASE_URL +
        generateCodeString(selectedFanModel, selectedOptions) +
        '.jpg'
    );
  }, [selectedOptions]);

  // console.log(JSON.stringify(data, null, 2))

  //https://res.cloudinary.com/dragster/image/upload/v1600263744/Fjaraskupan_2020/Kitchen_Fläkttyp_köksmiljö_köksfärg.jpg

  //https://res.cloudinary.com/dragster/image/upload/v1600263744/Fjaraskupan_2020/Kitchen_f_k3_s1.jpg
  //https://res.cloudinary.com/dragster/image/upload/v1600263744/Fjaraskupan_2020/Kitchen_f_k2_s1.jpg
  //https://res.cloudinary.com/dragster/image/upload/v1600263744/Fjaraskupan_2020/Kitchen_f_k1_s1.jpg

  //https://res.cloudinary.com/dragster/image/upload/v1600263744/Fjaraskupan_2020/2_k2_s1.jpg
  //https://res.cloudinary.com/dragster/image/upload/v1600263744/Fjaraskupan_2020/2_k2_s2.jpg

  //When selecting model:
  //https://res.cloudinary.com/dragster/image/upload/v1600263744/Fjaraskupan_2020/10510_8146_8192-8_2_k3_s2.jpg
  //https://res.cloudinary.com/dragster/image/upload/v1600263744/Fjaraskupan_2020/6107_8146_2_k3_s1.jpg
  //https://res.cloudinary.com/dragster/image/upload/v1600263744/Fjaraskupan_2020/1816_8146_8192-8_8195-8_2_k3_s1.jpg
  //https://res.cloudinary.com/dragster/image/upload/v1600263744/Fjaraskupan_2020/8146_8192-8_8195-8_2_k3_s1.jpg
  //https://res.cloudinary.com/dragster/image/upload/v1600263744/Fjaraskupan_2020/1818_8192-3_8190-6_8195-14_2_k3_s1.jpg
  //https://res.cloudinary.com/dragster/image/upload/v1600263744/Fjaraskupan_2020/1816_8146_8192-8_8195-10_2_k3_s1.jpg
  //Buy button link:
  //arcad_SJ6YM_0$s6107_0$s8146
  //aero_RT7RI_1$s10520_0$s8141_1$s8192-1
  //console.log(jsonData?.choice);
  //console.log(data)

  //https://res.cloudinary.com/dragster/image/upload/Fjaraskupan_2020/error.png

  function generateFanBuilderUri() {
    const fanObj = selectedOptions.find(opt => opt.id === FAN_TYPE_KEY);
    const fanIndex = selectedOptions.indexOf(fanObj);

    if (fanIndex !== -1) {
      selectedOptions.splice(fanIndex, 1);
      selectedOptions.unshift(fanObj);
    }

    if (selectedFanModel) {
      let modelOptions = selectedOptions.slice(4);
      let modelString = modelOptions.map(obj => obj.value).join('_0$s');

      const selectedProducSku = modelOptions[0]?.value;
      let selectedProductIndex = getActiveOptionIndex(
        selectedFanModel,
        selectedProducSku
      );

      return `${selectedProduct?.productName.toLowerCase()}_${randConfigValue}_${selectedProductIndex}$s${modelString}`;
    }

    return selectedOptions.map(obj => obj.value).join('_');
  }

  async function handleOptionChange(choice, optionValue) {
    setIsLoading(true);
    const optionKey = choice?.key ?? choice?.choiceHeading ?? choice;
    const index = selectedOptions.findIndex(obj => obj.id == optionKey);

    let newState;
    if (index >= 0) {
      newState = [
        ...selectedOptions.slice(0, index),
        { id: optionKey, value: optionValue },
        ...selectedOptions.slice(index + 1)
      ];
    } else {
      newState = selectedOptions.concat({ id: optionKey, value: optionValue });
    }

    //If "Fläkttyp" changes remove all properties related to model and only show kitchen
    if (optionKey == FAN_TYPE_KEY) {
      newState = newState.filter(
        opt =>
          opt.id == KITCHEN_ENV_KEY ||
          opt.id == KITCHEN_COLOR_KEY ||
          opt.id == FAN_TYPE_KEY
      );
      setSelectedFanModel(null);
    }

    if (optionKey == FAN_MODEL_KEY) {
      const fanProduct = choice?.options[0]?.singleChoiceProducts?.find(
        p => p.sku == optionValue
      );

      //Reset select-options state
      newState = newState.filter(
        opt =>
          opt.id == KITCHEN_ENV_KEY ||
          opt.id == KITCHEN_COLOR_KEY ||
          opt.id == FAN_TYPE_KEY ||
          opt.id == FAN_MODEL_KEY
      );

      const locale = cultureCode.split('_')[0];
      const fileName = `${fanProduct?.productName}_showroom_${locale}.json`;
      const fanModel = await fetchProductFile(fileName);
      if (!fanModel) {
        return;
      }

      //Set default values for fan model
      const fanDefaultObjects = getAllFanModelDefaultValues(fanModel.choice);

      for (let fanDefaultObject of fanDefaultObjects) {
        const index = newState.findIndex(obj => obj.id == fanDefaultObject.key);
        if (index >= 0) {
          newState[index].value = fanDefaultObject.products[0].sku;
        } else {
          newState = newState.concat({
            id: fanDefaultObject.key,
            value: fanDefaultObject.products[0].sku
          });
        }
      }

      setSelectedProduct(fanProduct);
      setSelectedFanModel(fanModel.choice);
      setSelectedOptions(newState);
      setIsLoading(false);
      setRandConfigValue(generateRandomConfigKey(5));

      return;
    }

    //Validate options to match newState
    const selectedWidthIndex = getActiveOptionIndex(
      selectedFanModel,
      newState[4]?.value
    );
    const allOptions = getAllFanModelDefaultValues(
      selectedFanModel?.options[selectedWidthIndex]?.choice,
      null
    );
    for (let opt of allOptions) {
      const stateSku = newState.find(obj => obj.id == opt.key)?.value;
      if (opt.products.find(p => p.sku == stateSku)) {
        continue;
      }
      const newSku = opt.products[0].sku;
      const index = newState.findIndex(obj => obj.id == opt.key);
      newState[index].value = newSku;
    }

    setSelectedOptions(newState);
    setIsLoading(false);
  }

  const generateOptionProducts = (choice, option) => {
    const singleChoiceProducts = option?.singleChoiceProducts;
    const includedProducts = option?.includedProducts;
    return (
      <>
        {singleChoiceProducts &&
          singleChoiceProducts.map((productOption, index) => {
            return (
              <ShowroomOption
                key={index}
                choice={choice}
                value={productOption.sku}
                label={
                  productOption['productName_' + cultureCode] ||
                  productOption?.productName
                }
                isChecked={
                  selectedOptions?.find(
                    opt =>
                      opt?.id == choice?.key || opt?.id == choice?.choiceHeading
                  )?.value == productOption.sku
                }
                onChange={handleOptionChange}
              />
            );
          })}
        {includedProducts &&
          includedProducts.map((productOption, index) => {
            return (
              <ShowroomOption
                key={index}
                choice={choice}
                value={productOption.sku}
                label={
                  productOption['productName_' + cultureCode] ||
                  productOption?.productName
                }
                isChecked={
                  selectedOptions?.find(
                    opt =>
                      opt?.id == choice?.key || opt?.id == choice?.choiceHeading
                  )?.value == productOption.sku
                }
                onChange={handleOptionChange}
              />
            );
          })}
      </>
    );
  };

  const generateSelect = choice => {
    return (
      <div className="showroom-option-container">
        {choice?.options?.map((option, index) => {
          return (
            <React.Fragment key={index}>
              {generateOptionProducts(choice, option)}
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const onAccordionClick = index => {
    if (index == activeAccIndex) {
      setActiveAccIndex(null);
      return;
    }
    setActiveAccIndex(index);
  };

  const redirectToFanBuilder = () => {
    history.push(
      `/${selectedProduct?.productName.toLowerCase()}-bygg-din-egen?config=${btoa(
        generateFanBuilderUri()
      )}`
    );
  };

  function renderSelectWithHeader(choice, index) {
    if (choice == null) {
      return <></>;
    }
    //Match for language in showroomData file.
    return (
      <ShowroomAccordion
        title={choice['choiceHeading_' + cultureCode] || choice.choiceHeading}
        isOpen={activeAccIndex == index}
        onClick={onAccordionClick}
        index={index}
      >
        {generateSelect(choice)}
      </ShowroomAccordion>
    );
  }

  const getActiveOptionIndex = (choice, productSku) => {
    let activeIndex = choice?.options?.findIndex(opt =>
      opt?.includedProducts?.find(product => product?.sku == productSku)
    );

    if (activeIndex == -1) {
      activeIndex = choice?.options?.findIndex(opt =>
        opt?.singleChoiceProducts?.find(product => product?.sku == productSku)
      );
    }
    return activeIndex;
  };

  function recursiveRenderSelection(choice, index) {
    if (!choice?.options || choice?.options?.length == 0) {
      return;
    }

    let activeValue = null;
    if (choice?.choiceHeading) {
      activeValue = selectedOptions?.find(
        opt => opt?.id == choice?.choiceHeading
      )?.value;
    } else {
      activeValue = selectedOptions?.find(opt => opt?.id == choice?.key)?.value;
    }

    const activeIndex = getActiveOptionIndex(choice, activeValue);
    const activeOption = choice?.options[activeIndex];
    // console.log(choice?.choiceHeading + " " + activeIndex)
    return (
      <>
        {renderSelectWithHeader(choice, index)}
        {activeOption?.choice &&
          recursiveRenderSelection(activeOption?.choice, index + 1)}
      </>
    );
  }

  const handleImageLoad = () => {
    if (showRoomImgRef.current) {
      const height = showRoomImgRef.current.clientHeight;
      setAccordionHeight(height); // Set height based on img element when loaded
    }
  };

  if (!category) {
    return (
      <CategoryWrapper>
        <CategoryHeaderLoadingState />;
      </CategoryWrapper>
    );
  }

  const createDownloadUrl = url => {
    const modifiedUrl = url.replace('/upload/', '/upload/fl_attachment/');
    return modifiedUrl;
  };

  return (
    <>
      <TrackPageLoad pageType={'Designa själv'} route={category.primaryRoute} />
      <Container>
        <ShowroomContainer>
          <div className="image-container">
            <React.Fragment>
              <ImageContainer>
                <img
                  alt="showroom"
                  id="showroom-image"
                  src={activeFallbackUrl ? activeFallbackUrl : activeImageUrl}
                  ref={showRoomImgRef}
                  onLoad={handleImageLoad}
                />
                <div>
                  <ShowroomBuyButton
                    text={t('Buy online')}
                    disabled={!selectedFanModel}
                    onClick={redirectToFanBuilder}
                  />
                  {!activeFallbackUrl && (
                    <a
                      href={createDownloadUrl(activeImageUrl)}
                      download="showroom.png"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <DownloadIcon />
                    </a>
                  )}
                </div>
              </ImageContainer>
            </React.Fragment>
          </div>
          <AccordionContainer
            className="accordion-container"
            height={accordionHeight}
          >
            <h1 className="showroom-title">Showroom</h1>
            {recursiveRenderSelection(getShoowroomData(showroomSettings), 0)}
            {selectedFanModel &&
              recursiveRenderSelection(selectedFanModel, 100)}
          </AccordionContainer>
        </ShowroomContainer>
      </Container>
    </>
  );
};

export default Category;
